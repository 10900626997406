import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { CFN_isAdmin } from 'utils/commFunction';

const useSubscription = (selectedCode) => {
  const location = useLocation();
  const storeCode = useSelector((state) => state.common.selectedStoreCode);
  const storeList = useSelector((state) => (state.auth.authUser ? state.auth.authUser.storeList : []));
  const { selectedStoreCode } = useSelector((state) => state.common);
  const roleName = useSelector((state) => state.auth.authUser?.roleName);
  const code = selectedCode || selectedStoreCode;
  const subscription = storeList.find((el) => el.code === code)?.subscription;
  const subscriptionPage = [
    'raw-material-status-forecast',
    // 'monthly-analysis',
    'usp-shipping',
  ].some((path) => location.pathname.includes(path));

  const [state, setState] = useState(true);

  useEffect(() => {
    if (!CFN_isAdmin(roleName)) {
      if (storeCode !== 'ALL') {
        if (subscription) {
          const { is_experience, product } = subscription;
          const subscriptionService = product === 'PREMIUM' || is_experience;
          setState(subscriptionService);
        }
      } else {
        setState(true);
      }
    }
  }, [roleName, storeList, subscription]);

  return state;
};

export default useSubscription;
