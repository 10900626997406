import { CFN_intl } from './commFunction';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import 'dayjs/locale/ko';
dayjs.locale('ko');

const utc = require('dayjs/plugin/utc');
dayjs.extend(duration);
dayjs.extend(utc);

export const getTodayDate = (format = 'DD.MM.YYYY') => {
  return dayjs().format(format);
};

export const getYesterdayDate = () => {
  return dayjs().subtract(1, 'day').format('DD.MM.YYYY');
};

export const timeFromNow = (date) => {
  const timestamp = dayjs(date).format('X');
  const newDate = dayjs.unix(timestamp);
  return dayjs(newDate).fromNow();
};

export const isToday = (date) => {
  return dayjs().isSame(dayjs(date), 'day');
};

export const getNewDate = (noOfDays, format = 'DD MMM, YYYY') => {
  return dayjs().add(noOfDays, 'day').format(format);
};

export const getDateElements = (date) => {
  const dateString = dayjs(date).format('dddd, MMMM DD YYYY, hh:mm A');
  const dateSections = dateString.split(',');
  const day = dateSections[0];
  const time = dateSections[2];
  const datePart = dateSections[1].trim().split(' ');
  return {
    day,
    time,
    date: {
      dateString: dateSections[1],
      month: datePart[0],
      date: datePart[1],
      year: datePart[2],
    },
  };
};

export const getTime = (date) => {
  const dateObj = dayjs(date, 'dddd, MMMM DD YYYY, hh:mm a');
  return dayjs(dateObj).format('LT');
};

export const isDatesSame = (dateA, dateB) => {
  return dayjs(dateA).isSame(dateB, 'day');
};

export const isDateAfter = (date) => {
  const todayDate = getTodayDate('dddd, MMMM DD YYYY, hh:mm a');
  return dayjs(todayDate).isAfter(date);
};

export const getDateinDesiredFormat = (date, format) => {
  return dayjs(date).format(format);
};

export const makeJSDateObject = (date) => {
  if (date) {
    return new Date(date.getTime());
  }
  return date;
};

/**
 * Get Formatted Date
 * @param date
 * @param format
 * @returns {string}
 */
export const getFormattedDate = (date, format = 'YYYY-MM-DD') => {
  if (dayjs(date, 'YYYY-MM-DD').isValid()) {
    return dayjs(date).format(format);
  }

  return '';
};

/**
 * Check Is dateTime of Tomorrow
 * @param inputDateTime
 * @returns {boolean}
 */
export const isTomorrow = (inputDateTime) => {
  const tomorrow = dayjs().add(1, 'day').format('YYYY-MM-DD');
  return dayjs(inputDateTime).isSame(tomorrow, 'day');
};

/**
 * Check Is dateTime of Yesterday
 * @param inputDateTime
 * @returns {boolean}
 */
export const isYesterday = (inputDateTime) => {
  const yesterday = dayjs().subtract(1, 'day').format('YYYY-MM-DD');
  return dayjs(inputDateTime).isSame(yesterday, 'day');
};

/**
 * Get Custom Date Time
 * @param value
 * @param unit
 * @param format
 * @returns {string}
 */
export const getCustomDateTime = (value = 0, unit = 'days', format = 'YYYY-MM-DD') => {
  const date = dayjs();
  if (value === 0) {
    return date.format(format);
  } else {
    return date.add(value, unit).format(format);
  }
};

export const getCustomAnyDateTime = (start, value = 0, unit = 'days', format = 'YYYY-MM-DD') => {
  const date = dayjs(start);
  if (value === 0) {
    return date.format(format);
  } else {
    return date.add(value, unit).format(format);
  }
};

export const getDateText = (date) => {
  if (isToday(date)) {
    return 'Today';
  } else if (isYesterday(date)) {
    return 'Yesterday';
  } else if (isTomorrow(date)) {
    return 'Tomorrow';
  } else {
    return date;
  }
};

export const getTimeDiffString = (date) => {
  const postDate = dayjs(date, 'ddd MMM DD YYYY kk:mm:ss Z');
  const currentDate = dayjs(new Date());
  const duration = dayjs.duration(currentDate.diff(postDate));
  const minutes = duration.asMinutes() | 0;
  const hours = duration.asHours() | 0;

  switch (true) {
    case minutes === 0:
      return 'Just now';
    case minutes < 60:
      return `${minutes} min`;
    case hours < 24:
      return `${hours} hours`;
    default:
      return postDate.format('DD MMM, YYYY');
  }
};

export const getCustomDateFormat = (date, format = 'YYYY-MM-DD HH:mm:ss') => {
  return dayjs(new Date(date)).format(format);
};

// 오늘 날짜 커스텀
export const getCustomTodayFormat = (format = 'YYYY-MM-DD HH:mm:ss') => {
  return dayjs(new Date()).format(format);
};

// 조회 기준 당일 시작 시간
// ex) 2021-07-07 00:00:00
export const getTodayStartDate = (format = 'YYYY-MM-DD 00:00:00') => {
  return dayjs(new Date()).format(format);
};

// 조회 기준 당일 현재 시간
// ex) 2021-07-07 17:33:03
export const getTodayEndDate = (format = 'YYYY-MM-DD HH:mm:ss') => {
  return dayjs(new Date()).format(format);
};

// 조회 기준 당일 현재 시간 -1 hour
// ex) 2021-07-07 17:33:03
export const getTodayEndDatePrev1Hour = (format = 'YYYY-MM-DD HH:mm:ss') => {
  return dayjs(new Date()).subtract(1, 'hours').format(format);
};

// 조회 기준 전일 동일 시간
// ex) 2021-07-06 17:33:03
export const getYesterdayEndDate = (format = 'YYYY-MM-DD HH:mm:ss') => {
  return dayjs(new Date()).subtract(1, 'days').format(format);
};

// 조회 기준 이번달 시작 시간
export const getThisMonthStartData = (format = 'YYYY-MM-01 00:00:00') => {
  return dayjs(new Date()).format(format);
};

//조회 기준 전달 시작 시간
export const getPreviousMonthStartDate = (format = 'YYYY-MM-01 00:00:00') => {
  return dayjs(new Date()).subtract(1, 'month').format(format);
};

//조회 기준 전달 동일 날짜 시간
export const getPreviousMonthEndDate = (format = 'YYYY-MM-DD HH:mm:ss') => {
  return dayjs(new Date()).subtract(1, 'month').format(format);
};

//조회 기준 전달 마지막 일
export const getLastMonthEndDate = (format = 'YYYY-MM-DD') => {
  return dayjs(new Date()).subtract(1, 'month').endOf('month').format(format);
};

//지난 7일
export const getLast7days = (startFormat = 'YYYY-MM-DD 00:00:00', endFormat = 'YYYY-MM-DD 23:59:59') => {
  const date = dayjs();
  const startDate = date.subtract(7, 'day').format(startFormat);
  const endDate = date.subtract(1, 'day').format(endFormat);

  return {
    start: startDate,
    end: endDate,
  };
};

//오늘 포함 지난 7일
export const getLast7daysWithToday = (startFormat = 'YYYY-MM-DD 00:00:00', endFormat = 'YYYY-MM-DD 23:59:59') => {
  const date = dayjs();
  const startDate = date.subtract(7, 'days').format(startFormat);
  const endDate = date.format(endFormat);

  return {
    start: startDate,
    end: endDate,
  };
};

/**
 * 시간 label 리턴
 * @param {date} date
 * @returns 시간 값 리턴
 */
export const timeLabelName = (date) => {
  const tempDate = new Date(date);
  const tmpHour = dayjs(tempDate).format('HH');
  if (tmpHour === '00') {
    return `${dayjs(tempDate).format('M/DD H')}시`;
  } else {
    return dayjs(tempDate).format('HH');
  }
};

/**
 * 시간 label 리턴
 * @param {date} date
 * @param {string} format default 'HH:mm'
 * @returns
 */
export const timeLabelNameA = (date, format = 'HH:mm') => {
  const tempDate = new Date(date);
  return dayjs(tempDate).format(format);
};

/**
 * 시간 label 리턴
 * @param {date} date
 * @param {string} format default 'HH:mm:ss'
 * @returns
 */
export const timeLabelNameB = (date, format = 'HH:mm:ss') => {
  const tempDate = new Date(date);
  return dayjs(tempDate).format(format);
};

// 지난 기간 대비
// ex) 조회 날짜가 8/2  ~ 8/4 라면
//     지난 기간은 7/30 ~ 8/1 이어야 한다.
export const getCustomPrevPeriodDate = (startdate, enddate, format = 'YYYY-MM-DD') => {
  const tempStartDate = new Date(startdate);
  const tempEndDate = new Date(enddate);

  const diffdays = dayjs(tempEndDate).diff(dayjs(tempStartDate), 'days');
  const tempPrevEndDate = dayjs(tempStartDate).subtract(1, 'days').format(format);
  const tempPrevStartDate = dayjs(tempPrevEndDate, format).subtract(diffdays, 'days').format(format);

  const prevPeriod = {
    prevStartDate: tempPrevStartDate,
    prevEndDate: tempPrevEndDate,
  };

  return prevPeriod;
};

/**
 * 날짜간 차이 계산
 * @param {*} startdate
 * @param {*} enddate
 * @param {*} unit
 * @returns
 */
export const getDiffdays = (startdate, enddate, unit = 'days') => {
  return dayjs(enddate).diff(dayjs(startdate), unit);
};

/**
 * 날짜 포멧 변경
 * @param {String} date
 * @param {string} format
 * @returns
 */
export const convertFormatDate = (date, format = 'YYYY-MM-DD') => {
  return getCustomDateFormat(date, format);
};

export const DATE_INIT = '1970/01/01';

/**
 * 초를 시간으로 변환
 * mm:ss ~ hh:mm:ss
 * @param {Number} secs
 * @returns
 */

export const ConvertSecondstoTime = (secs) => {
  if (secs > 212400) {
    return CFN_intl('const.string.check');
  } else if (secs < 60) {
    return dayjs.utc(secs * 1000).format('mm:ss');
  } else {
    return dayjs.duration(secs, 'seconds').format('HH:mm:ss');
  }
};

/**
 *
 * @param {string} date
 * @param {string} locale
 * @returns
 */
export const convertFormatLocalizedDate = (date, locale = 'ko') => {
  return dayjs(date).locale(locale).format('YYYY/M/D (ddd)');
};

/**
 * 주어진 날짜(date)가 현재 주(week) 안에 있는지를 확인
 * @param {string} date
 * @returns
 */
export const isDate1InThisWeek = (date) => {
  const weekStart = dayjs().startOf('week').add(1, 'day');
  const weekEnd = dayjs().endOf('week').add(1, 'day');

  return dayjs(date).isBetween(weekStart, weekEnd, null, '[]');
};

/**
 * 주어진 날짜(date)가 현재 날짜보다 이전인지 확인
 * @param {string} date
 * @returns
 */
export const isPast = (date) => dayjs(date).isBefore(dayjs(), 'day');

/**
 * 주어진 날짜(date)가 속한 월(month)의 첫 주의 시작 날짜를 계산
 * 주어진 날짜(date)의 1일이 ‘일요일‘ 인 경우 전 월의 6일 + 해당 월의 1일
 * @param {string} date
 * @returns
 */
export const getStartOfFirstWeekOfMonth = (date) => {
  const start = dayjs(date).startOf('month').startOf('week');

  return dayjs(date).startOf('month').day() === 0 ? start.subtract(1, 'week') : start;
};

/**
 * 주어진 날짜(date)가 속한 월(month)의 마지막 주의 끝 날짜를 계산
 * @param {string} date
 * @returns
 */
export const getEndOfLastWeekOfMonth = (date) => {
  return dayjs(date).endOf('month').endOf('week');
};

export const getStartOfMonth = (date) => dayjs(date).startOf('month').format('YYYY-MM-DD');

export const getEndOfMonth = (date) => dayjs(date).endOf('month').format('YYYY-MM-DD');
